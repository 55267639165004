import Vue from 'vue';
import App from './App.vue';
import router from './router'
import http from './api/http'
import iview from 'iview';

import './index.less'
import './assets/login.css'

import componentLib from './components/common/customComponent.js'
Vue.use(componentLib);

Vue.use(iview, {
   transfer: true
});

Vue.prototype.$http = http

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false

let vue = new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

export default vue