import LoginHome from '@/components/page/LoginHome.vue'
import ContentHome from '@/components/page/ContentHome.vue'

let routeList = [{
  path: '/',
  redirect: '/account'
},{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/page/Login.vue')
    }
  ]
},
{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/loginCode',
      name: 'loginCode',
      component: () => import('@/components/page/LoginCode.vue')
    }
  ]
},
{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/register',
      name: 'register',
      component: () => import('@/components/page/Register.vue')
    }
  ]
},{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: () => import('@/components/page/ForgetPassword.vue')
    }
  ]
},{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/bindAccount',
      name: 'bindAccount',
      component: () => import('@/components/page/BindAccount.vue')
    }
  ]
},{
  path: '',
  component: LoginHome,
  children: [
    {
      path: '/loginSuccess',
      name: 'loginSuccess',
      component: () => import('@/components/page/LoginSuccess.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/account',
      name: 'account',
      component: () => import('@/components/page/Account.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/bulletin',
      name: 'bulletin',
      component: () => import('@/components/page/Bulletin.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/bulletinDetail',
      name: 'bulletinDetail',
      component: () => import('@/components/page/BulletinDetail.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/order',
      name: 'order',
      component: () => import('@/components/page/Order.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/enterprise',
      name: 'enterprise',
      component: () => import('@/components/page/Enterprise.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/activity',
      name: 'activity',
      component: () => import('@/components/page/Activity.vue')
    }
  ]
},{
  path: '',
  component: ContentHome,
  children: [
    {
      path: '/activityDetail',
      name: 'activityDetail',
      component: () => import('@/components/page/ActivityDetail.vue')
    }
  ]
},{
  path: '/userAgreement',
  name: 'userAgreement',
  component: () => import('@/components/page/UserAgreement.vue')
}];

export default routeList
