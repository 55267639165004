import Cookies from 'js-cookie'
import moment from 'moment'
import CryptoJs from 'crypto-js'

const TOKEN_KEY = 'chajian_token';
//const USER_KEY = 'chajian_info';
	
export const setUserInfo = (user) =>{
  Cookies.set(TOKEN_KEY, user.token, { expires: 30});
	//localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export const getToken = () => {
  return Cookies.get(TOKEN_KEY);
}
export const updateToken = (name,token)=>{
  if(name == 'account' && token && !getToken()){
     setUserInfo({token: token});
  }
}
export const clearToken = ()=>{
   Cookies.remove(TOKEN_KEY);
}

export const getCacheData = (key,account)=>{
   if(!key){
      return ;
   }
   if(account){
      let encryptStr = encrypt(JSON.stringify(account));
      localStorage.setItem(key,encryptStr);
   }else {
      let value = localStorage.getItem(key);
      if(value){
         let decryptStr = decrypt(value);
         if(decryptStr){
             return JSON.parse(decryptStr);
         }
      }
      return null;
   }
}
export const removeCacheData = (key)=>{
   if(key){
      localStorage.removeItem(key);
   }
}
export const getTempCacheData = (key,account)=>{
  if(!key){
     return ;
  }
  if(account){
     sessionStorage.setItem(key,JSON.stringify(account));
  }else {
     let value = sessionStorage.getItem(key);
     if(value){
        return JSON.parse(value);
     }
     return null;
  }
}

let errMsgObj = {
  'Network Error': '网络错误'
}

export const getErrMsg = (error) =>{
   let errMsg = '';
   if(typeof error === 'object'){
       if(error.message){
          errMsg = errMsgObj[error.message];
       }
   }
   return errMsg || '请求失败';
}

export const formatDate = (time,format) =>{
  if(!time){
      return '';
  }
  return moment(time).format(format || "YYYY-MM-DD HH:mm:ss");
}

export const getUrlNoParam = ()=>{
   let url = window.location.href;
   if(url.indexOf('?') != -1){
       url = url.split('?')[0];
   }
   return url;
}

export const replaceState = (url)=>{
   if(history.replaceState){
       url = url || getUrlNoParam();
       history.replaceState(null,null,url);
   }
}

export const getUrlParam = (param)=>{
   let url = window.location.href;
   if(url.indexOf('?') != -1){
      let query = url.split('?')[1];
      let vars = query.split("&");
      for(let i=0;i<vars.length;i++) {
         let pair = vars[i].split("=");
         if(pair[0] == param){
            return pair[1];
         }
      }
   }
   return null;
}

const KEY = 'dsffsexxwesdfe37';

export const encrypt = (word)=>{
   let key = CryptoJs.enc.Utf8.parse(KEY);
   let srcs = CryptoJs.enc.Utf8.parse(word);
   let encrypted = CryptoJs.AES.encrypt(srcs,key,{
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
   });
   return encrypted.toString();
}

export const decrypt = (word)=>{
   let key = CryptoJs.enc.Utf8.parse(KEY);
   let decrypt = CryptoJs.AES.decrypt(word,key,{
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
   });
   return CryptoJs.enc.Utf8.stringify(decrypt).toString();
}