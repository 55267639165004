<template>
<div id="root">
    <section class="ant-layout ant-layout-has-sider layouts-wrap">
      <aside class="ant-layout-sider ant-layout-sider-light slider-menu" style="flex: 0 0 80px; max-width: 80px; min-width: 80px; width: 80px">
        <div class="ant-layout-sider-children">
           <ul class="ant-menu ant-menu-root ant-menu-inline ant-menu-light">
             <li v-for="(menu,index) in menuList" :key="index"
                 :class='{"ant-menu-item-selected": index == activeIndex}'
                 class="ant-menu-item" 
                 style="padding-left: 12px">
                 <span @click="gotoPage(menu)" class="ant-menu-title-content">
                    <img :src="menu.img" />
                    <span class="ellipsis">{{menu.name}}</span>
                 </span>
            </li>
          </ul>
        </div>
      </aside>
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import accountImg from '@/assets/static/icon_mine_nor.09a6e2cf.svg';
import bulletinImg from '@/assets/static/icon_notice_nor.f0df444a.svg';
import orderImg from '@/assets/static/icon_order_nor.1bd7d34b.svg';
import activityImg from '@/assets/static/icon_friend.45282fc3.svg';

export default {
  name: "contentHome",
  data() {
    return {
      menuList: [
        {name: '账号',img: accountImg,routeName: 'account'},
        {name: '消息',img: bulletinImg,routeName: 'bulletin',subrouteName: 'bulletinDetail'},
        {name: '订单',img: orderImg,routeName: 'order'},
        {name: '活动',img: activityImg,routeName: 'activity',subrouteName: 'activityDetail'}
      ],
      activeIndex: 0
    }
  },
  methods: {
    gotoPage(menu){
       this.$router.push({name: menu.routeName});
    },
    setActivityIndex(routeName){
       this.menuList.forEach((menu,index)=>{
          if(menu.routeName == routeName || (menu.subrouteName && menu.subrouteName.indexOf(routeName) != -1)){
             this.activeIndex = index;
          }
       });
    }
  },
  watch: {
    '$route': function(route){
       this.setActivityIndex(route.name);
    }
  },
  mounted(){
    this.setActivityIndex(this.$route.name);
  }
};
</script>
<style scoped>
#root {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>