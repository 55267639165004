import axios from 'axios'
import {getToken,getErrMsg,clearToken,getTempCacheData} from "@/utils"
import router,{getCurrentRouteName} from '@/router'
import {Message} from 'iview'
import context from '@/main.js'

export const baseUrl = process.env.VUE_APP_BASE_URL;

const service = axios.create({
  baseURL: baseUrl, // api的base_url
  timeout: 60000    // 请求超时时间
});

export default service

// request拦截器，实现loading加载
service.interceptors.request.use(config => {
  setLoad(config,true);
  if(!config.headers['Content-Type']){
     config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  let token = getToken();
  if(token){
     config.headers.Authorization = 'Bearer ' + token;
  }
  let cid = getTempCacheData('cid');
  if(cid){
     config.headers.client_id = cid;
  }
  return config;
}, error => {
  context.$bus.$emit('loading', false);
  Message.error(getErrMsg(error))
  return Promise.reject(error)
})

// response拦截器，实现loading关闭
service.interceptors.response.use(resp => {
  setLoad(resp.config,false);
  if(resp.headers['content-type'] == 'application/octet-stream'){
      return resp;
  }
  const {code,msg,data} = resp.data
  let routeName = getCurrentRouteName();
  if(code == 401 && routeName != 'login'){
     clearToken();
     router.replace({name: 'login',query: {backName: routeName}});
     return Promise.reject(msg)
  }else if(code != 200){
     Message.error(msg);
     return Promise.reject(msg)
  }
  return data
}, error => {
  context.$bus.$emit('loading', false);
  Message.error(getErrMsg(error))
  return Promise.reject(error)
})

function setLoad(obj,flag){
	if(obj && obj.loading !== false){
		context.$bus.$emit('loading', flag);
	}
}
