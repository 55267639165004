import Vue from 'vue'
import Router from 'vue-router'
import routes from './router'
import {getToken,updateToken} from '@/utils'

Vue.use(Router)

const router = new Router({
  routes,
  mode: 'hash'
})
let noLoginNames = ['login','loginCode','register','forgetPassword','bindAccount','loginSuccess','userAgreement'];

router.beforeEach((to, from, next) => {
  if(to.name == 'userAgreement'){
     next();
     return ;
  }
  updateToken(to.name,to.query.token);
  const token = getToken();
  if(!token && noLoginNames.filter((name)=>{return name == to.name}).length > 0){
    next();
  }else if(!token){
    next({
      name: 'login'
    });
  }else if (token && (to.name === 'login' || to.name == 'loginCode')) {
    next({
      name: 'account'
    })
  } else {
    next()
  }
})

export const getCurrentRouteName = ()=>{
  return router.app._route.name;
}

export default router

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
