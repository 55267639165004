<template>
    <div id="root">
      <div class="i-header">
        <div class="i-header-head" style="position: static">
          <div class="content-width">
             <img width="112" src="../../assets/static/logo.png" style="margin-right: 48px; cursor: pointer"/>
          </div>
        </div>
        <div class="content-children flex-center">
            <router-view></router-view>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "loginHome",
  data() {
    return {}
  },
  methods: {}
};
</script>
<style scoped>
#root {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>