<template>
  <div id="app">
    <loading></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import {getUrlParam,getTempCacheData} from '@/utils';

export default {
  name: 'app',
  created(){
     let clientId = getUrlParam('client_id');
     if(clientId){
         getTempCacheData('cid',clientId);
     }
  }
}
</script>